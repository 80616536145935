(function ($) {
  $.fn.handlebarSelector = function () {
    return this.each(function (index, element) {
      let settings = {
        uri: DK.url('finder/handlebar/range-finder.jsonp'),
        uriProducts: DK.url('finder/handlebar/products.jsonp'),
        landingFunction: null,
        handlebarSelectorId: null,
        visibilityPollingInterval: 100,
        measurementSelectors: '[data-measure]',
        metricId: '#hb-metric',
        seoNameId: '#hb-seoname',
        mmSuffix: 'mm',
        impSuffix: '"'
      };
      let $form = $(element);
      let $metric, $seoName;
      init();
      function init() {
        uniqueId();
        $metric = $form.find('[name="metric"]').val();
        $seoName = $form.find('[name="seoname"]').val();
        populateRange($form, {
          metric: $metric,
          seoName: $seoName
        });
        $form.data('handlebarSizeLoaded', true);
        initEvents();
      }
      function initEvents() {
        $('.hbf__min, .hbf__max').on('change', function (e) {
          let $select = $(e.currentTarget);
          const updateCounts = true;
          getParamValues(updateCounts);
          evaluateSelectValues($select);
        });
        $('#hbf-ps-group button').on('click', function () {
          const $btn = $(this),
            $btnValue = $btn.val();
          $btn.siblings().removeClass('hbf-ps__link--selected');
          $btn.addClass('hbf-ps__link--selected');
          $form.find('[name="seoname"]').attr('value', $btnValue);
          $metric = $form.find('[name="metric"]').val();
          $seoName = $form.find('[name="seoname"]').val();

          //Reset Url to all handlebar values with new powersport
          const $url = `/${$seoName}/handlebars?view_all=true`;
          $form.attr('action', $url);
          populateRange($form, {
            metric: $metric,
            seoName: $seoName
          });
        });
        $('.hbf__reset').on('click', () => {
          resetAll($form);
        });
        $('#metricBtn, #usBtn').on('click', function () {
          const metricInput = $form.find('input#hb-metric');
          $(this).attr('id') === 'metricBtn' ? metricInput.attr('value', true) : metricInput.attr('value', false);
          switchUnits($(this).attr('id'));
        });
        $($form).on('submit', function (e) {
          e.preventDefault();
          // Update the products count
          let $centerWidthMin = $form.find('#centerWidth-min').val(),
            $centerWidthMax = $form.find('#centerWidth-max').val(),
            $diameterMin = $form.find('#diameter-min').val(),
            $diameterMax = $form.find('#diameter-max').val(),
            $overallWidthMin = $form.find('#overallWidth-min').val(),
            $overallWidthMax = $form.find('#overallWidth-max').val(),
            $riseMin = $form.find('#rise-min').val(),
            $riseMax = $form.find('#rise-max').val(),
            $pullbackMin = $form.find('#pullback-min').val(),
            $pullbackMax = $form.find('#pullback-max').val();
          $metric = $form.find('[name="metric"]').val();
          $seoName = $form.find('[name="seoname"]').val();
          let url = updateUrl($seoName + '/handlebars', {
            handlebarCenterWidth: `${$centerWidthMin}-${$centerWidthMax}`,
            handlebarDiameter: `${$diameterMin}-${$diameterMax}`,
            handlebarOverallWidth: `${$overallWidthMin}-${$overallWidthMax}`,
            handlebarPullback: `${$pullbackMin}-${$pullbackMax}`,
            handlebarRise: `${$riseMin}-${$riseMax}`
          });

          // turn off active ride
          $.ajax({
            url: '/ride/off',
            async: false
          });
          window.location.href = url;
        });
      }
      function getParamValues(updateCounts) {
        // Update the products count
        let $centerWidthMin = $form.find('#centerWidth-min').val(),
          $centerWidthMax = $form.find('#centerWidth-max').val(),
          $diameterMin = $form.find('#diameter-min').val(),
          $diameterMax = $form.find('#diameter-max').val(),
          $overallWidthMin = $form.find('#overallWidth-min').val(),
          $overallWidthMax = $form.find('#overallWidth-max').val(),
          $riseMin = $form.find('#rise-min').val(),
          $riseMax = $form.find('#rise-max').val(),
          $pullbackMin = $form.find('#pullback-min').val(),
          $pullbackMax = $form.find('#pullback-max').val();
        $metric = $form.find('[name="metric"]').val();
        $seoName = $form.find('[name="seoname"]').val();
        if (updateCounts) {
          getProductCounts($form, {
            metric: $metric,
            seoName: $seoName,
            centerWidth: {
              min: $centerWidthMin,
              max: $centerWidthMax
            },
            diameter: {
              min: $diameterMin,
              max: $diameterMax
            },
            overallWidth: {
              min: $overallWidthMin,
              max: $overallWidthMax
            },
            pullback: {
              min: $pullbackMin,
              max: $pullbackMax
            },
            rise: {
              min: $riseMin,
              max: $riseMax
            }
          });
        }
      }
      function updateUrl(baseUrl, params) {
        const url = new URL(window.location.origin + '/' + baseUrl);
        for (key in params) {
          url.searchParams.append(key, params[key]);
        }
        return url.href;
      }
      function evaluateSelectValues($selector) {
        let $parent = $selector.parent();
        let $minValue = $parent.find('.hbf__min').val(),
          $maxValue = $parent.find('.hbf__max').val(),
          selectOption = null,
          minIndex,
          maxIndex;

        /* This little gem makes sure you can't select a min value that's
        higher than the max or a max value lower than the min. So once you select
        either a min or max it'll hide the values over or under that value. */

        if ($selector.hasClass('hbf__min')) {
          let maxOptions = $parent.find('.hbf__max').children();
          maxOptions.show().removeAttr('disabled');
          for (let i = 0; i < maxOptions.length; i++) {
            if (maxOptions.eq(i).val() === $minValue) {
              selectOption = maxOptions[i];
              maxIndex = selectOption.index;
              break;
            }
          }
          $(selectOption).prevAll().hide().attr('disabled');
        } else {
          let minOptions = $parent.find('.hbf__min').children();
          minOptions.show().removeAttr('disabled');
          for (let i = 0; i < minOptions.length; i++) {
            if (minOptions.eq(i).val() === $maxValue) {
              selectOption = minOptions[i];
              minIndex = selectOption.index;
              break;
            }
          }
          $(selectOption).nextAll().hide().attr('disabled');
        }
        let slider = $parent.parent().find('.hbf-slider'),
          sliderInstance = slider.data('ionRangeSlider');
        if (sliderInstance !== undefined) {
          sliderInstance.update({
            from: maxIndex,
            to: minIndex
          });
        }
      }
      function loadSizeList(data, unit) {
        $.each(data, function (index) {
          /* We want to ignore the products data since it's not relevant to
          populating the sliders. */
          if (index !== 'products') {
            let $mparent = $form.find(`[data-measure='${index}']`),
              $slider = $mparent.find(`#${index}-slider`),
              $selects = $mparent.find('select');
            let customValues = this,
              textArray = [];
            $.each(customValues, function (index, value) {
              //Make a new array of just the text values for ionRangeSlider values
              textArray.push(value.text);
            });

            /*Worth noting that ionRangeSlider's min and max's are actually
            the index of the values, not the actual values themselves.
            Since ours are fractions and whatnot, it can't seem to use those
            values to calculate where the slider should be.*/

            let indexMax = customValues.length,
              from = 0,
              to = indexMax - 1;
            let sliderInstance = $slider.data('ionRangeSlider');
            if (sliderInstance) {
              //Used for unit and powersport changes
              sliderInstance.update({
                to: to,
                from: from,
                postfix: unit,
                values: textArray
              });
            } else {
              //Initial slider call
              $slider.ionRangeSlider({
                type: 'double',
                skin: 'flat',
                from: from,
                to: to,
                min: 0,
                max: $selects.children().length,
                force_edges: true,
                postfix: unit,
                grid_snap: true,
                values: textArray,
                onFinish: updateSelects
              });
            }
            populateSelects(customValues, $selects, unit);
            if (!$selects.children('[value=-1]').length || $selects.children().length !== 0) {
              evaluateSelectValues($selects);
            }
          }
        });
      }
      function updateSelects(data) {
        //Updates the selects to reflect the slider changes
        let $parent = data.input.parents('[data-measure]'),
          $selects = $parent.find('select'),
          $minSelect = $parent.find('select:first'),
          $maxSelect = $parent.find('select:last');
        $selects.children().removeAttr('selected');

        /* Hackery to search for the values, minus the unit. */
        let filterMin = $minSelect.children().filter(function () {
          return $(this).text().replace(/\"|mm/g, '') === data.from_value.toString();
        });
        let filterMax = $maxSelect.children().filter(function () {
          return $(this).text().replace(/\"|mm/g, '') === data.to_value.toString();
        });

        /* We only want to fire the change event on the select that was changed,
        otherwise it'll send two ajax requests */

        if ($minSelect.val() !== filterMin.val()) {
          $minSelect.prop('value', filterMin.val());
          $minSelect.trigger('change');
        } else if ($maxSelect.val() !== filterMax.val()) {
          $maxSelect.prop('value', filterMax.val());
          $maxSelect.trigger('change');
        }
      }
      function updateProductCounts(count) {
        /** Update search button text with number of results.
         ** Disable button when zero results.*/
        let $countBtn = $form.find('.hbf__results-wrapper button'),
          countTxt = `Show ${count} results`;
        $countBtn.text(countTxt);
        count === 0 ? $countBtn.attr('disabled', true) : $countBtn.attr('disabled', false);
      }
      function populateSelects(data, $selects, unit) {
        $selects.children().remove();

        // Populate the selects.
        $.each(data, function (index, value) {
          $('<option />').attr('value', value.id * 1).html(value.text + unit).appendTo($selects);
        });
        const $minValue = $selects.first().children(':first').val(),
          $maxValue = $selects.last().children(':last').val();

        // Auto select the lowest min value and highest max value
        $selects.first().prop('value', $minValue);
        $selects.last().prop('value', $maxValue);
      }
      function getProductCounts($parent, params) {
        // Updates just the product counts
        let spinner = new Spinner();
        $.ajax({
          type: 'POST',
          async: true,
          url: settings.uriProducts,
          contentType: 'application/json; charset=utf-8',
          data: JSON.stringify(params),
          beforeSend: function () {
            DK.displayBlocker($parent);
            spinner.spin($parent.get()[0]);
          },
          complete: function () {
            DK.hideBlocker($parent);
            spinner.stop();
          }
        }).retry({
          times: 3,
          timeout: 1000
        }).done(function (data) {
          updateProductCounts(data.products);
        });
      }
      function populateRange($parent, params) {
        // Initial population of all the values including product counts
        let unit = params.metric === 'false' ? settings.impSuffix : settings.mmSuffix,
          spinner = new Spinner();
        $.ajax({
          type: 'POST',
          async: true,
          url: settings.uri,
          contentType: 'application/json; charset=utf-8',
          data: JSON.stringify(params),
          beforeSend: function () {
            DK.displayBlocker($parent);
            spinner.spin($parent.get()[0]);
          },
          complete: function () {
            DK.hideBlocker($parent);
            spinner.stop();
          }
        }).retry({
          times: 3,
          timeout: 1000
        }).done(function (data) {
          updateProductCounts(data.products);
          loadSizeList(data, unit);
          getParamValues();
        }).fail(function (xhr, textStatus, errorThrown) {
          DK.toggleAlert($parent.find('.alert-box'), 'Error fetching handlebar information. Please refresh the page and try again.', 'alert');
        });
      }
      function resetAll($form) {
        // Resets all the values
        const $selector = $form.find('.hbf__col');
        $metric = $form.find('[name="metric"]').val();
        $seoName = $form.find('[name="seoname"]').val();
        const $url = `/${$seoName}/handlebars?view_all=true`;
        $form.attr('action', $url);
        populateRange($form, {
          metric: $metric,
          seoName: $seoName
        });
        evaluateSelectValues($selector);
      }
      function switchUnits(id) {
        /* Switch from inches to metric or back */
        const metricInput = $form.find('[name="metric"]'),
          idSelector = $(`#${id}`);
        id === 'metricBtn' ? metricInput.val('true') : metricInput.val('false');
        idSelector.prev().attr('checked', 'checked');
        idSelector.siblings('label').prev().removeAttr('checked');
        const $selector = $form.find('.hbf__col');
        $metric = $form.find('[name="metric"]').val();
        $seoName = $form.find('[name="seoname"]').val();
        $selector.find('select').empty();
        populateRange($form, {
          metric: $metric,
          seoName: $seoName
        });
      }
      function uniqueId() {
        /* Create a unique ID for the handlebar selector. Just in case
        there ends up being more than one instance on a page */
        if (!element.id) {
          element.id = `handlebar-size-selector-${Math.random().toString(36).substr(2, 6)}`;
        }
        settings.handlebarSelectorId = `#${element.id}`;
      }
    });
  };
})(jQuery);
$(function () {
  $('[data-handlebar-size-selector]').handlebarSelector();
});